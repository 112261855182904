import React from "react";
import { instances, instance, AWSInstance } from "../data/awsinstances";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

interface AWSInstanceSelectProps {
  value: string | undefined;
  disabled?: boolean;
  onChange: (name?: string, instance?: AWSInstance) => void;
}

const selectLabel = (instance: AWSInstance) => {
  return `${instance.name} (vCPU: ${instance.vCPU}, Memory: ${
    instance.memory
  } GiB, Disk: ${instance.ssdsize} GiB)`;
};

export const AWSInstanceSelect = (props: AWSInstanceSelectProps) => {
  const { onChange } = props;
  const disabled = !!props.disabled;

  let { value } = props;
  if (value === undefined) {
    value = "";
  }

  return (
    <FormControl>
      <InputLabel htmlFor="instance-select">Instance</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        onChange={event => {
          const value = event.target.value;
          if (value === "") {
            onChange();
          } else {
            onChange(value, instance(value));
          }
        }}
        inputProps={{
          name: "instance",
          id: "instance-select"
        }}
      >
        <MenuItem value="">None</MenuItem>
        {instances().map(instance => (
          <MenuItem key={instance.name} value={instance.name}>
            {selectLabel(instance)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
