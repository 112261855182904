const instanceTypes = [
  { name: "c5d.18xlarge", vCPU: 72, memory: 144, ssdsize: 900, numssds: 2 },
  { name: "r5d.4xlarge", vCPU: 16, memory: 128, ssdsize: 300, numssds: 2 },
  { name: "r5d.24xlarge", vCPU: 96, memory: 768, ssdsize: 900, numssds: 4 },
  { name: "r5d.12xlarge", vCPU: 48, memory: 384, ssdsize: 900, numssds: 2 },
  { name: "z1d.3xlarge", vCPU: 12, memory: 96, ssdsize: 450, numssds: 1 },
  { name: "z1d.6xlarge", vCPU: 24, memory: 192, ssdsize: 900, numssds: 1 },
  { name: "z1d.12xlarge", vCPU: 48, memory: 384, ssdsize: 900, numssds: 2 },
  { name: "x1e.xlarge", vCPU: 4, memory: 122, ssdsize: 120, numssds: 1 },
  { name: "x1e.2xlarge", vCPU: 8, memory: 244, ssdsize: 240, numssds: 1 },
  { name: "x1e.4xlarge", vCPU: 16, memory: 488, ssdsize: 480, numssds: 1 },
  { name: "x1e.8xlarge", vCPU: 32, memory: 976, ssdsize: 960, numssds: 1 },
  { name: "x1e.16xlarge", vCPU: 64, memory: 1952, ssdsize: 1920, numssds: 1 },
  {
    name: "x1e.32xlarge",
    vCPU: 128,
    memory: 3904,
    ssdsize: 1920,
    numssds: 2
  },
  { name: "x1.16xlarge", vCPU: 64, memory: 976, ssdsize: 1920, numssds: 1 },
  { name: "x1.32xlarge", vCPU: 128, memory: 1952, ssdsize: 1920, numssds: 2 },
  { name: "i3.metal", vCPU: 72, memory: 512, ssdsize: 1920, numssds: 8 },
  { name: "i3.16xlarge", vCPU: 64, memory: 488, ssdsize: 1920, numssds: 8 },
  { name: "i3.8xlarge", vCPU: 32, memory: 244, ssdsize: 1920, numssds: 4 },
  { name: "i3.4xlarge", vCPU: 16, memory: 122, ssdsize: 1920, numssds: 2 },
  { name: "i3.2xlarge", vCPU: 8, memory: 61, ssdsize: 1920, numssds: 1 }
];

export interface AWSInstance {
  readonly name: string;
  readonly vCPU: number;
  readonly memory: number;
  readonly ssdsize: number;
  readonly numssds: number;
}

export function instance(name: string): AWSInstance | undefined {
  return instances().find(instance => instance.name === name);
}

export function instances(): AWSInstance[] {
  return instanceTypes;
}
