import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import configureStore from "./store";

import Projects from "./components/Projects";
import Summary from "./components/Summary";
import Collections from "./components/Collections";

import "./index.css";
import "typeface-roboto";
import Wizard from "./components/Wizard";
import { Router } from "react-router-dom";

import history from "./history";
import SecureRoute from "./components/SecureRoute";
import Auth from "./auth/Auth";
import withAuth from "./hoc/withAuth";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/Theme";
import ErrorBoundary from "./components/ErrorBoundary";

const store = configureStore();

const handleAuthentication = (auth: Auth, location: Location) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
          <Switch>
            <SecureRoute
              exact
              path="/projects/:id/collections"
              render={(props: any) => (
                <Wizard id={props.match.params.id} {...props}>
                  <Collections id={props.match.params.id} />
                </Wizard>
              )}
            />
            <SecureRoute
              exact
              path="/projects/:id"
              render={(props: any) => (
                <Wizard id={props.match.params.id} {...props}>
                  <Summary id={props.match.params.id} />
                </Wizard>
              )}
            />
            <SecureRoute exact path="/" component={Projects} />
            <Route
              path="/callback"
              render={withAuth((props: any) => {
                handleAuthentication(props.auth, document.location);
                return null;
              })}
            />
            <Route render={() => <div>Invalid URL</div>} />
          </Switch>
        </ErrorBoundary>
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
