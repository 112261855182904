import React from "react";
import Auth from "../auth/Auth";

const auth = new Auth();

export interface WithAuthProps {
  auth?: Auth;
}

export default function withAuth<P extends object>(
  Component: React.ComponentType<P>
) {
  return (props: P & WithAuthProps) => {
    props = {
      ...props,
      auth
    };
    return <Component {...props} />;
  };
}
