import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Collection,
  Field,
  FieldType,
  CollectionType
} from "../store/projects/types";
import AddIcon from "@material-ui/icons/Add";
import {
  Theme,
  WithStyles,
  withStyles,
  Divider,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Fab
} from "@material-ui/core";
import { presetCollection } from "../functions/collection";

import CollectionFormProperties from "./CollectionFormProperties";
import { fieldNameValid } from "../functions/field";
import CollectionDialogFieldRow from "./CollectionDialogFieldRow";

const styles = (theme: Theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  fab: {
    margin: theme.spacing.unit
  }
});

const presetFormCollection = () => {
  let collection = presetCollection();

  let type: "document" | "edge";
  if (collection.type === CollectionType.EDGE) {
    type = "edge";
  } else {
    type = "document";
  }

  return Object.assign(
    {},
    {
      ...collection,
      type
    }
  );
};

interface CollectionDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  validate?: (title: string) => boolean;
  onClose: () => void;
  onSave: (collection: Collection) => void;
  collection?: Collection;
}

const CollectionDialog = (props: CollectionDialogProps) => {
  const { classes, collection } = props;

  const initialState = Object.assign({}, collection || presetFormCollection());

  let title;
  let submitTitle;
  if (collection) {
    title = "Edit Collection";
    submitTitle = "Edit";
  } else {
    title = "Create Collection";
    submitTitle = "Create";
  }

  const clearState = () => {
    setState({ ...initialState });
  };

  const [{ name, type, fields, documents, vertices }, setState] = useState(
    initialState
  );

  const addField = () => {
    setState(prevState => ({
      ...prevState,
      fields: [
        ...prevState.fields,
        {
          name: "",
          type: FieldType.INT32,
          length: 0,
          usedInTraversal: false
        }
      ]
    }));
  };

  const filterField = (previousFields: Field[], path: number[]): Field[] => {
    const searchIndex = path.shift();

    if (path.length === 0) {
      return previousFields.filter((field, index) => index !== searchIndex);
    } else {
      return previousFields.map((field, index) => {
        if (index === searchIndex) {
          if (field.type === FieldType.OBJECT) {
            return {
              ...field,
              object: filterField(field.object!, path)
            };
          } else if (field.type === FieldType.ARRAY) {
            return {
              ...field,
              array: filterField([field.array!], path)[0]
            };
          } else {
            return field;
          }
        } else {
          return field;
        }
      });
    }
  };

  const removeField = (path: number[]) => {
    setState(prevState => ({
      ...prevState,
      fields: filterField(prevState.fields, path)
    }));
  };

  const createFieldCopyName = (fields: Field[], name: string) => {
    for (let i = 1; i < 65335; i++) {
      const tempName = name + "_" + i;
      if (fields.find(field => field.name === tempName) === undefined) {
        return tempName;
      }
    }
    throw new Error(`Couldn't find a new name for ${name}`);
  };

  const copyField = (field: Field) => {
    setState(prevState => {
      let newField = {
        ...field,
        name: createFieldCopyName(prevState.fields, field.name)
      };
      return {
        ...prevState,
        fields: [...prevState.fields, newField]
      };
    });
  };

  const createCollectionObject = (): Collection => {
    let collectionType;
    if (type === "document") {
      collectionType = CollectionType.DOCUMENT;
    } else {
      collectionType = CollectionType.EDGE;
    }
    return {
      name,
      documents,
      vertices,
      type: collectionType,
      fields
    };
  };

  function doSave(collection: Collection) {
    props.onSave(collection);
    clearState();
  }

  function save(event: React.FormEvent<HTMLFormElement> | React.MouseEvent) {
    event.preventDefault();
    doSave(createCollectionObject());
  }

  const adjustCollectionTypeFields = (fields: Field[], type: string) => {
    if (type === "edge") {
      const from = {
        name: "_from",
        type: FieldType.STRING,
        length: 20,
        usedInTraversal: false
      };
      const to = {
        name: "_to",
        type: FieldType.STRING,
        length: 20,
        usedInTraversal: true
      };
      fields.splice(3, 0, from, to);
      return [...fields];
    } else {
      return fields.filter(
        field => field.name !== "_from" && field.name !== "_to"
      );
    }
  };

  const setCollectionType = (type: "document" | "edge") => {
    setState(prevState => ({
      ...prevState,
      type,
      fields: adjustCollectionTypeFields(prevState.fields, type)
    }));
  };

  const isValid =
    name.length > 0 &&
    documents > 0 &&
    fields.every(
      field =>
        fieldNameValid(field.name) &&
        (field.type !== FieldType.STRING || field.length > 0)
    );

  const setFormProperty = function(name: string) {
    return function<T>(value: T) {
      setState(prevState => ({ ...prevState, [name]: value }));
    };
  };

  const changeFields = function(
    previousFields: Field[],
    path: number[],
    changeFn: (field: Field) => Field
  ): Field[] {
    const searchIndex = path.shift();
    return previousFields.map((field, index) => {
      if (index === searchIndex) {
        if (path.length === 0) {
          return changeFn(field);
        } else {
          if (field.type === FieldType.ARRAY) {
            return {
              ...field,
              array: changeFields([field.array!], path, changeFn)[0]
            };
          } else if (field.type === FieldType.OBJECT) {
            return {
              ...field,
              object: changeFields(field.object!, path, changeFn)
            };
          } else {
            console.error(field);
            throw new Error("Unexpected field");
          }
        }
      } else {
        return field;
      }
    });
  };

  const setField = function(path: number[], field: Field) {
    setState(prevState => ({
      ...prevState,
      fields: changeFields(prevState.fields, path, () => field)
    }));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xl"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form onSubmit={save}>
        <DialogContent>
          <CollectionFormProperties
            name={name}
            type={type}
            numberOfDocuments={documents}
            numberOfVertices={vertices}
            setName={setFormProperty("name")}
            setType={setCollectionType}
            setNumberOfDocuments={setFormProperty("documents")}
            setNumberOfVertices={setFormProperty("vertices")}
          />
          <Divider />
          <Typography variant="h6">Fields</Typography>
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fab}
            onClick={addField}
          >
            <AddIcon />
          </Fab>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Length</TableCell>
                  <TableCell>Index</TableCell>
                  {type === CollectionType.EDGE ? (
                    <TableCell>Used in traversal</TableCell>
                  ) : null}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <CollectionDialogFieldRow
                    key={index}
                    collectionType={type as CollectionType}
                    field={field}
                    path={[index]}
                    classes={classes}
                    setField={setField}
                    removeField={removeField}
                    copyField={copyField}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={save}
              disabled={!isValid}
              color="primary"
              type="submit"
            >
              {submitTitle}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(CollectionDialog);
