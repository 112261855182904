import React, { useState } from "react";

import withAuth from "../hoc/withAuth";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Project, Collection } from "../store/projects/types";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../store/state";
import { Action } from "redux";
import { deleteCollection } from "../store/projects/actions";
import CollectionRow from "./CollectionRow";

interface CollectionListProps {
  auth?: any;
  project: Project;
  onEdit: (index: number, collection: Collection) => void;
  onEditRequest: (index: number) => void;
  deleteCollection: (
    accessToken: string,
    projectId: string,
    index: number
  ) => Promise<void>;
}

const CollectionList = (props: CollectionListProps) => {
  const { auth, deleteCollection, project } = props;

  const [confirmDeleteIndex, setConfirmDeleteIndex] = useState();

  let confirmDeleteDialog;
  if (confirmDeleteIndex !== undefined) {
    const doDelete = async () => {
      const accessToken = await auth.getAccessToken();
      await deleteCollection(accessToken, project.id!, confirmDeleteIndex);
      setConfirmDeleteIndex(undefined);
    };
    confirmDeleteDialog = (
      <ConfirmDeleteDialog
        onCancel={() => setConfirmDeleteIndex(undefined)}
        onConfirm={doDelete}
      />
    );
  }

  const onDeleteRequest = (index: number) => {
    setConfirmDeleteIndex(index);
  };

  const { onEditRequest, onEdit } = props;
  const { collections } = props.project;

  return (
    <React.Fragment>
      {confirmDeleteDialog}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Number of Documents</TableCell>
            <TableCell align="right">Fields</TableCell>
            <TableCell align="right">Document Size</TableCell>
            <TableCell align="right">Total DataSize</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.map((collection, index) => (
            <CollectionRow
              key={index}
              collection={collection}
              collectionIndex={index}
              onEdit={(collection: Collection) => onEdit(index, collection)}
              onEditRequest={onEditRequest}
              onDeleteRequest={onDeleteRequest}
            />
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, Action>
): Pick<CollectionListProps, "deleteCollection"> => {
  return {
    deleteCollection: (accessToken: string, projectId: string, index: number) =>
      dispatch(deleteCollection(accessToken, projectId, index))
  };
};

export default withAuth(
  connect(
    undefined,
    mapDispatchToProps
  )(CollectionList)
);
