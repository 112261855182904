import React, { useRef } from "react";
import { Project } from "../store/projects/types";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { dataSizeMemory } from "../functions/project";
import { gib } from "../format/size";

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer"
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      }
    },
    iconWrapper: {
      display: "inline-block"
    }
  });

interface ProjectRowProps extends WithStyles<typeof styles> {
  project: Project;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  gotoProject: (id: string) => void;
}

const ProjectRow = (props: ProjectRowProps) => {
  const { project, onEdit, onDelete, classes, gotoProject } = props;
  const deleteButtonRef = useRef(null);
  const editButtonRef = useRef(null);
  return (
    <TableRow
      className={classNames(classes.tableRow, classes.tableRowHover)}
      onClick={(event: React.MouseEvent) => {
        // the row callback will trigger everywhere in the row
        // check if we hit one of the buttons and ignore the event in case of this
        let current: Node | undefined = event.target as Node;
        while (current) {
          if (
            current === deleteButtonRef.current ||
            current === editButtonRef.current
          ) {
            return;
          }
          current = current.parentNode as Node | undefined;
        }
        gotoProject(project.id!);
      }}
    >
      <TableCell component="th" scope="row">
        {project.name}
      </TableCell>
      <TableCell align="right">{project.collections.length}</TableCell>
      <TableCell align="right">{gib(dataSizeMemory(project))}</TableCell>
      <TableCell align="right">
        <div
          className={classNames(classes.iconWrapper)}
          ref={editButtonRef}
          onClick={() => onEdit(project.id!)}
        >
          <IconButton aria-label="Edit" color="primary">
            <EditIcon />
          </IconButton>
        </div>
        <div
          ref={deleteButtonRef}
          className={classNames(classes.iconWrapper)}
          onClick={() => onDelete(project.id!)}
        >
          <IconButton aria-label="Delete" color="primary">
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ProjectRow);
