import { Project, Collection } from "./types";

export const ActionTypes = {
  START_FETCH_PROJECTS: "START_FETCH_PROJECTS",
  FINISH_FETCH_PROJECTS: "FINISH_FETCH_PROJECTS",
  ERROR_FETCH_PROJECTS: "ERROR_FETCH_PROJECTS",

  START_CREATE_PROJECT: "START_CREATE_PROJECT",
  FINISH_CREATE_PROJECT: "FINISH_CREATE_PROJECT",
  ERROR_CREATE_PROJECT: "ERROR_CREATE_PROJECT",

  START_EDIT_PROJECT: "START_EDIT_PROJECT",
  FINISH_EDIT_PROJECT: "FINISH_EDIT_PROJECT",
  ERROR_EDIT_PROJECT: "ERROR_EDIT_PROJECT",

  START_DELETE_PROJECT: "START_DELETE_PROJECT",
  FINISH_DELETE_PROJECT: "FINISH_DELETE_PROJECT",
  ERROR_DELETE_PROJECT: "ERROR_DELETE_PROJECT",

  START_CREATE_COLLECTION: "START_CREATE_COLLECTION",
  FINISH_CREATE_COLLECTION: "FINISH_CREATE_COLLECTION",
  ERROR_CREATE_COLLECTION: "ERROR_CREATE_COLLECTION",

  START_EDIT_COLLECTION: "START_EDIT_COLLECTION",
  FINISH_EDIT_COLLECTION: "FINISH_EDIT_COLLECTION",
  ERROR_EDIT_COLLECTION: "ERROR_EDIT_COLLECTION",

  START_DELETE_COLLECTION: "START_DELETE_COLLECTION",
  FINISH_DELETE_COLLECTION: "FINISH_DELETE_COLLECTION",
  ERROR_DELETE_COLLECTION: "ERROR_DELETE_COLLECTION",

  START_SET_REPLICATIONFACTOR: "START_SET_REPLICATIONFACTOR",
  FINISH_SET_REPLICATIONFACTOR: "FINISH_SET_REPLICATIONFACTOR",
  ERROR_SET_REPLICATIONFACTOR: "ERROR_SET_REPLICATIONFACTOR",

  START_SET_DC2DC: "START_SET_DC2DC",
  FINISH_SET_DC2DC: "FINISH_SET_DC2DC",
  ERROR_SET_DC2DC: "ERROR_SET_DC2DC"
};

export interface StartFetchProjectsAction {
  type: typeof ActionTypes.START_FETCH_PROJECTS;
}

export interface FinishFetchProjectsAction {
  type: typeof ActionTypes.FINISH_FETCH_PROJECTS;
  payload: Project[];
}

export interface ErrorFetchProjectsAction {
  type: typeof ActionTypes.ERROR_FETCH_PROJECTS;
  payload: Error;
}

export interface StartCreateProjectAction {
  type: typeof ActionTypes.START_CREATE_PROJECT;
  payload: Project;
}

export interface FinishCreateProjectAction {
  type: typeof ActionTypes.FINISH_CREATE_PROJECT;
  payload: Project;
}

export interface ErrorCreateProjectAction {
  type: typeof ActionTypes.ERROR_CREATE_PROJECT;
  payload: Error;
}

export interface StartEditProjectAction {
  type: typeof ActionTypes.START_EDIT_PROJECT;
  payload: Project;
}

export interface FinishEditProjectAction {
  type: typeof ActionTypes.FINISH_EDIT_PROJECT;
}

export interface ErrorCreateProjectAction {
  type: typeof ActionTypes.ERROR_EDIT_PROJECT;
  payload: Error;
}

export interface CreateCollectionRequest {
  projectId: string;
  collection: Collection;
}

export interface StartCreateCollectionAction {
  type: typeof ActionTypes.START_CREATE_COLLECTION;
  payload: CreateCollectionRequest;
}

export interface FinishCreateCollectionAction {
  type: typeof ActionTypes.FINISH_CREATE_COLLECTION;
}

export interface ErrorCreateCollectionAction {
  type: typeof ActionTypes.ERROR_CREATE_COLLECTION;
  payload: Error;
}

export interface EditCollectionRequest {
  projectId: string;
  index: number;
  collection: Collection;
}

export interface StartEditCollectionAction {
  type: typeof ActionTypes.START_EDIT_COLLECTION;
  payload: EditCollectionRequest;
}

export interface FinishEditCollectionAction {
  type: typeof ActionTypes.FINISH_EDIT_COLLECTION;
}

export interface ErrorEditCollectionAction {
  type: typeof ActionTypes.ERROR_EDIT_COLLECTION;
  payload: Error;
}

export interface StartDeleteProjectAction {
  type: typeof ActionTypes.START_DELETE_PROJECT;
  payload: string;
}

export interface FinishDeleteProjectAction {
  type: typeof ActionTypes.FINISH_DELETE_PROJECT;
}

export interface ErrorDeleteProjectAction {
  type: typeof ActionTypes.ERROR_DELETE_PROJECT;
  payload: Error;
}

export interface DeleteCollectionRequest {
  projectId: string;
  index: number;
}

export interface StartDeleteCollectionAction {
  type: typeof ActionTypes.START_DELETE_COLLECTION;
  payload: DeleteCollectionRequest;
}

export interface FinishDeleteCollectionAction {
  type: typeof ActionTypes.FINISH_DELETE_COLLECTION;
}

export interface ErrorDeleteCollectionAction {
  type: typeof ActionTypes.ERROR_DELETE_COLLECTION;
  payload: Error;
}

export type ProjectActionTypes =
  | StartFetchProjectsAction
  | FinishFetchProjectsAction
  | ErrorFetchProjectsAction
  | StartCreateProjectAction
  | FinishCreateProjectAction
  | ErrorCreateProjectAction
  | StartDeleteProjectAction
  | FinishDeleteProjectAction
  | ErrorDeleteProjectAction
  | StartCreateCollectionAction
  | FinishCreateCollectionAction
  | ErrorCreateCollectionAction
  | StartDeleteCollectionAction
  | FinishDeleteCollectionAction
  | ErrorDeleteCollectionAction;
