import { MachineType, Project } from "../store/projects/types";

import {
  dataSizeMemory as collectionDataSizeMemory,
  dataSizeDisk as collectionDataSizeDisk
} from "./collection";
import { instance } from "../data/awsinstances";

function growth(project: Project, value: number) {
  return (
    value * Math.pow(1 + project.growth / 100, project.numberOfGrowthUnits)
  );
}

export function dataSizeMemory(project: Project): number {
  return project.collections.reduce((sum, collection) => {
    return (sum += collectionDataSizeMemory(collection));
  }, 0);
}

export function dataSizeMemoryAfterGrowth(project: Project): number {
  return growth(project, dataSizeMemory(project));
}

export function dataSizeDisk(project: Project): number {
  return project.collections.reduce((sum, collection) => {
    return (sum += collectionDataSizeDisk(collection));
  }, 0);
}

export function dataSizeDiskAfterGrowth(project: Project): number {
  return growth(project, dataSizeDisk(project));
}

export function memoryIncludingReplicas(project: Project): number {
  let localMemorySize = (dataSizeMemory(project) * project.sizeHotData) / 100;
  return (
    localMemorySize +
    ((localMemorySize * project.memoryForReplicas) / 100) *
      (project.replicationFactor - 1)
  );
}

export function memoryIncludingReplicasAfterGrowth(project: Project): number {
  return growth(project, memoryIncludingReplicas(project));
}

export function memoryIncludingReplicasAndCaches(project: Project): number {
  return (
    memoryIncludingReplicas(project) /
    ((100 -
      (project.systemMemory +
        project.rocksDBWriteCache +
        project.queryResultCache)) /
      100)
  );
}

export function memoryIncludingReplicasAndCachesAfterGrowth(
  project: Project
): number {
  return growth(project, memoryIncludingReplicasAndCaches(project));
}

export function numberOfMachines(project: Project): number {
  let num = Math.ceil(
    (memoryIncludingReplicas(project) / availableMachineMemory(project)) *
      project.datacenters
  );
  if (num < project.datacenters * 3) {
    return project.datacenters * 3;
  } else {
    return num;
  }
}

export function numberOfMachinesAfterGrowth(project: Project): number {
  let num = Math.ceil(
    (memoryIncludingReplicasAfterGrowth(project) /
      availableMachineMemory(project)) *
      project.datacenters
  );
  if (num < project.datacenters * 3) {
    return project.datacenters * 3;
  } else {
    return num;
  }
}

export function numberOfLicenses(project: Project): number {
  let machines = numberOfMachines(project);
  let x = Math.ceil(machineMemory(project) / (256 * 1024 * 1024 * 1024));
  if (x > machines) {
    return x;
  } else {
    return machines;
  }
}

export function numberOfLicensesAfterGrowth(project: Project): number {
  let machines = numberOfMachinesAfterGrowth(project);
  let x = Math.ceil(machineMemory(project) / (256 * 1024 * 1024 * 1024));
  if (x > machines) {
    return x;
  } else {
    return machines;
  }
}

function machineMemoryGiB(project: Project): number {
  if (project.machineType === MachineType.Generic) {
    return project.genericMachineMemory;
  } else {
    const inst = instance(project.awsInstanceType);
    if (inst === undefined) {
      return 0;
    } else {
      return inst.memory;
    }
  }
}

export function machineMemory(project: Project): number {
  return machineMemoryGiB(project) * 1024 * 1024 * 1024;
}

export function availableMachineMemory(project: Project): number {
  return (
    machineMemory(project) -
    ((machineMemory(project) * project.systemMemory) / 100 +
      (machineMemory(project) * project.rocksDBWriteCache) / 100 +
      (machineMemory(project) * project.queryResultCache) / 100)
  );
}

export function totalDiskSpaceUncompressed(project: Project): number {
  return dataSizeDisk(project) * (1 + project.lsmTreeOverhead / 100);
}

export function peakLoadSizeUncompressed(project: Project): number {
  return (
    dataSizeDisk(project) * (1 + project.peakSpaceFactorUncompressed / 100)
  );
}

export function peakLoadSizeUncompressedUpdated(project: Project): number {
  return (
    dataSizeDisk(project) +
    (((dataSizeDisk(project) * project.peakSpaceFactorUncompressed) / 100) *
      project.updatedData) /
      100
  );
}

export function totalDiskSpaceCompressed(project: Project): number {
  return (dataSizeDisk(project) * project.compressionRate) / 100;
}

export function peakLoadSizeCompressed(project: Project): number {
  return (
    (dataSizeDisk(project) *
      (1 + project.peakSpaceFactorCompressed / 100) *
      project.compressionRate) /
    100
  );
}

export function peakLoadSizeCompressedUpdated(project: Project): number {
  return (
    (dataSizeDisk(project) * project.compressionRate) / 100 +
    (((((dataSizeDisk(project) * project.peakSpaceFactorCompressed) / 100) *
      project.compressionRate) /
      100) *
      project.updatedData) /
      100
  );
}

export function totalDiskSpaceCompressedAfterGrowth(project: Project): number {
  return growth(project, totalDiskSpaceCompressed(project));
}

export function peakLoadSizeCompressedUpdatedAfterGrowth(project: Project) {
  return growth(project, peakLoadSizeCompressedUpdated(project));
}

export function peakLoadSizeCompressedAfterGrowth(project: Project) {
  return growth(project, peakLoadSizeCompressed(project));
}

export function peakLoadSizeUncompressedUpdatedAfterGrowth(project: Project) {
  return growth(project, peakLoadSizeUncompressedUpdated(project));
}

export function peakLoadSizeUncompressedAfterGrowth(project: Project) {
  return growth(project, peakLoadSizeUncompressed(project));
}

export function totalDiskSpaceUncompressedAfterGrowth(project: Project) {
  return growth(project, totalDiskSpaceUncompressed(project));
}
