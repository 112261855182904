import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import filesize from "filesize";

interface PercentageOfTextFieldProps {
  label: string;
  value: number;
  percentageOf: number;
  helperText?: string;

  onChange: (value: number) => void;
}

const handleNumberChange = (cb: (value: number) => void) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    cb(value);
  };
};

const PercentageOfTextField = (props: PercentageOfTextFieldProps) => {
  return (
    <TextField
      label={props.label}
      value={props.value}
      onChange={handleNumberChange(props.onChange)}
      margin="normal"
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
        endAdornment: (
          <span style={{ marginLeft: "10px", whiteSpace: "nowrap" }}>
            {filesize((props.value / 100) * props.percentageOf, {
              standard: "iec",
              round: 1
            })}
          </span>
        )
      }}
      helperText={props.helperText}
    />
  );
};

export default PercentageOfTextField;
