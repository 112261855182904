import {
  Collection,
  Field,
  FieldType,
  CollectionType
} from "../store/projects/types";
import { fieldSize, fieldIndexSize, rawFieldSize } from "./field";

function convertToField(key: string, value: any): Field {
  const valueType = typeof value;
  let partialField;
  if (valueType === "string") {
    partialField = {
      type: FieldType.STRING,
      length: value.length
    };
  } else if (valueType === "number") {
    let type = FieldType.DOUBLE;
    if (Number.isInteger(value)) {
      if (value >= -128 && value < 128) {
        type = FieldType.INT8;
      } else if (value >= -32768 && value < 32768) {
        type = FieldType.INT16;
      } else if (value >= -2147483648 && value < 2147483648) {
        type = FieldType.INT32;
      } else if (value >= -9223372036854775808 && value < 9223372036854775808) {
        type = FieldType.INT64;
      }
    }
    partialField = {
      type,
      length: 0
    };
  } else if (valueType === "boolean") {
    partialField = {
      type: FieldType.BOOL,
      length: 0
    };
  } else if (valueType === "object") {
    if (value === null) {
      partialField = {
        type: FieldType.NULL,
        length: 0
      };
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        throw new Error(
          `Array ${key} doesn't contain element. Need an example document.`
        );
      }
      partialField = {
        type: FieldType.ARRAY,
        length: value.length,
        array: convertToField("element", value[0])
      };
    } else {
      partialField = {
        type: FieldType.OBJECT,
        length: 0,
        object: parseTemplateObject(value)
      };
    }
  } else {
    throw new Error(`Unexpected type ${valueType}`);
  }
  return {
    ...partialField,
    name: key,
    usedInTraversal: false
  };
}

function parseTemplateObject(template: object) {
  return Object.entries(template).map(([key, value]) => {
    return convertToField(key, value);
  });
}

export function fromTemplateDocument(
  name: string,
  type: CollectionType,
  documents: number,
  vertices: number,
  template: string
): Collection {
  const document = JSON.parse(template);
  if (typeof document !== "object" || document === null) {
    throw new Error("Need to provide an object");
  }

  let fields = parseTemplateObject(document);

  let internal = internalFields(type);
  if ("_key" in document) {
    const keyField = fields.find(field => field.name === "_key")!;
    if (keyField.type !== FieldType.STRING) {
      throw new Error("_key must be a STRING");
    }
    internal = internal.map(field => {
      if (field.name === "_key") {
        field.length = keyField.length;
      }
      return field;
    });
  }
  return {
    name,
    type,
    documents,
    vertices,
    fields: [
      ...internal,
      ...fields.filter(field => !field.name.startsWith("_"))
    ]
  };
}

export function internalFields(type: CollectionType): Field[] {
  if (type === CollectionType.EDGE) {
    return [
      {
        name: "_key",
        type: FieldType.STRING,
        length: 11,
        usedInTraversal: false
      },
      {
        name: "_id",
        type: FieldType.STRING,
        length: 8,
        usedInTraversal: false
      },
      {
        name: "_rev",
        type: FieldType.STRING,
        length: 11,
        usedInTraversal: false
      },
      {
        name: "_from",
        type: FieldType.STRING,
        length: 20,
        usedInTraversal: false
      },
      {
        name: "_to",
        type: FieldType.STRING,
        length: 20,
        usedInTraversal: true
      }
    ];
  } else {
    return [
      {
        name: "_key",
        type: FieldType.STRING,
        length: 11,
        usedInTraversal: false
      },
      {
        name: "_id",
        type: FieldType.STRING,
        length: 8,
        usedInTraversal: false
      },
      {
        name: "_rev",
        type: FieldType.STRING,
        length: 11,
        usedInTraversal: false
      }
    ];
  }
}

export function presetCollection(): Collection {
  return {
    name: "",
    type: CollectionType.DOCUMENT,
    documents: 10000,
    vertices: 10000,
    fields: internalFields(CollectionType.DOCUMENT)
  };
}

export function edgeIndexEntries(collection: Collection): number {
  if (collection.type !== CollectionType.EDGE) {
    return 0;
  }

  const from = collection.fields.find(field => field.name === "_from")!;
  const to = collection.fields.find(field => field.name === "_to")!;
  return 9 * 2 + from.length + 9 * 2 + to.length + from.length + to.length + 8;
}

function newEdgeCache(collection: Collection) {
  const from = collection.fields.find(field => field.name === "_from")!;
  const traversalAttributes = collection.fields
    .filter(field => field.usedInTraversal)
    .reduce((sum, field) => sum + rawFieldSize(field) + 8, 0);

  return (
    (from.length +
      (collection.documents / collection.vertices) * traversalAttributes) *
    2 *
    collection.vertices
  );
}

export function dataSizeMemory(collection: Collection): number {
  if (collection.type === CollectionType.DOCUMENT) {
    return documentSize(collection) * collection.documents;
  } else {
    return newEdgeCache(collection);
  }
}

export function dataSizeDisk(collection: Collection): number {
  return (
    (documentSize(collection) - edgeIndexEntries(collection)) *
    collection.documents
  );
}

function fieldSizeSum(collection: Collection): number {
  return collection.fields.reduce((sum: number, field: Field) => {
    sum += fieldSize(field);
    return sum;
  }, 0);
}

function indicesSize(collection: Collection): number {
  const secondaryIndices = collection.fields
    .filter(field => field.index !== undefined)
    .reduce((sum, field) => {
      sum += fieldIndexSize(field);
      return sum;
    }, 0);

  const key = collection.fields.find(field => field.name === "_key")!;
  return 8 + key.length + 1 + 16 + 4 + secondaryIndices;
}

export function documentSize(collection: Collection): number {
  return (
    16 +
    velocypackDocumentSize(collection) +
    4 +
    indicesSize(collection) +
    edgeIndexEntries(collection)
  );
}

export function velocypackDocumentSize(collection: Collection): number {
  let baseSize = 1 + fieldSizeSum(collection);
  let add;
  let factor;
  if (baseSize + 8 + collection.fields.length * 4 > 65535) {
    factor = 4;
    add = 8;
  } else if (baseSize + 4 + collection.fields.length * 2 > 255) {
    factor = 2;
    add = 4;
  } else {
    factor = 1;
    add = 2;
  }

  return baseSize + add + collection.fields.length * factor;
}
