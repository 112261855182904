import React, { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps } from "react-router";

import withProjects from "../hoc/withProjects";

import {
  withStyles,
  Theme,
  WithStyles,
  createStyles
} from "@material-ui/core/styles";
import ProjectDialog from "./ProjectDialog";
import ProjectList from "./ProjectList";
import { createProject, editProject } from "../store/projects/actions";
import { connect } from "react-redux";
import { Project } from "../store/projects/types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { RootState } from "../store/state";
import LogoutButton from "./LogoutButton";

import history from "../history";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    title: {
      flexGrow: 1
    },
    icon: {
      marginRight: theme.spacing.unit * 2
    },
    heroUnit: {
      backgroundColor: theme.palette.background.paper
    },
    heroContent: {
      maxWidth: 600,
      margin: "0 auto",
      padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`
    },
    heroButtons: {
      marginTop: theme.spacing.unit * 4
    },
    main: {
      padding: "24px"
    }
  });

interface ProjectsProps extends RouteComponentProps, WithStyles<typeof styles> {
  auth?: any;
  createProject: (accessToken: string, project: Project) => Promise<any>;
  editProject: (accessToken: string, project: Project) => Promise<any>;
  projects: Project[];
}

const Projects = (props: ProjectsProps) => {
  const { projects, classes, auth, editProject, createProject } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState();

  if (!auth.isAdmin()) {
    const project = auth.getProject();
    if (project) {
      history.replace("/projects/" + project);
    } else {
      throw new Error("You don't have any project configured.");
    }
    return null;
  }

  const saveProject = async (project: Project) => {
    const accessToken = await auth.getAccessToken();
    setSelectedProject(undefined);
    setDialogOpen(false);
    if (project.id !== undefined) {
      await editProject(accessToken, project);
    } else {
      await createProject(accessToken, project);
    }
  };

  const onEdit = (projectId: string) => {
    setDialogOpen(true);
    setSelectedProject(projects.find(project => project.id === projectId));
  };

  let dialog = null;
  if (dialogOpen) {
    dialog = (
      <ProjectDialog
        open={dialogOpen}
        project={selectedProject}
        onClose={() => setDialogOpen(false)}
        onSave={saveProject}
      />
    );
  }

  // grrr....withAuth somehow messes up all the type info
  // @ts-ignore
  const projectList = <ProjectList projects={projects} onEdit={onEdit} />;

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* <CameraIcon className={classes.icon} /> */}
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            ArangoDB Data Sizer
          </Typography>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              ArangoDB Data Sizer
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              Welcome to the ArangoDB Data Sizer. Describe your projects and
              estimate your current need of servers and licenses.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                  >
                    Create a new project
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        {projectList}
        {dialog}
      </main>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    projects: state.projects.projects
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, Action>
): Pick<ProjectsProps, "createProject" | "editProject"> => {
  return {
    createProject: (accessToken: string, project: Project) =>
      dispatch(createProject(accessToken, project)),
    editProject: (accessToken: string, project: Project) =>
      dispatch(editProject(accessToken, project))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withProjects(withStyles(styles)(Projects)));
