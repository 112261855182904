import { ProjectActionTypes, Project, ActionTypes } from "./types";
import {
  StartCreateCollectionAction,
  CreateCollectionRequest,
  EditCollectionRequest,
  DeleteCollectionRequest,
  StartDeleteCollectionAction,
  ErrorCreateCollectionAction,
  ErrorDeleteCollectionAction,
  ErrorDeleteProjectAction,
  StartDeleteProjectAction,
  StartCreateProjectAction,
  ErrorCreateProjectAction,
  StartEditProjectAction,
  StartEditCollectionAction,
  ErrorEditCollectionAction,
  FinishFetchProjectsAction,
  ErrorFetchProjectsAction,
  FinishCreateProjectAction
} from "./actiontypes";

export interface ProjectsState {
  projects: Project[];
  projectsFetched: boolean;

  fetchingProjects: boolean;
  fetchProjectsError: any | null;

  creatingProject: null | Project;
  createProjectError: any | null;

  editingProject: null | Project;
  editProjectError: any | null;

  deletingProject: null | string;
  deleteProjectError: any | null;

  creatingCollection: null | CreateCollectionRequest;
  createCollectionError: any | null;

  editingCollection: null | EditCollectionRequest;
  editCollectionError: any | null;

  deletingCollection: null | DeleteCollectionRequest;
  deleteCollectionError: any | null;
}

const initialState: ProjectsState = {
  projects: [],
  projectsFetched: false,

  fetchingProjects: false,
  fetchProjectsError: null,

  creatingProject: null,
  createProjectError: null,

  editingProject: null,
  editProjectError: null,

  deletingProject: null,
  deleteProjectError: null,

  creatingCollection: null,
  createCollectionError: null,

  editingCollection: null,
  editCollectionError: null,

  deletingCollection: null,
  deleteCollectionError: null
};

function projects(state = initialState, action: ProjectActionTypes) {
  switch (action.type) {
    case ActionTypes.START_FETCH_PROJECTS:
      return {
        ...state,
        fetchingProjects: true,
        fetchProjectsError: null
      };
    case ActionTypes.FINISH_FETCH_PROJECTS:
      return {
        ...state,
        projectsFetched: true,
        projects: (action as FinishFetchProjectsAction).payload,
        fetchingProjects: false,
        fetchProjectsError: null
      };
    case ActionTypes.ERROR_FETCH_PROJECTS:
      return {
        ...state,
        fetchingProjects: false,
        fetchProjectsError: (action as ErrorFetchProjectsAction).payload
      };
    case ActionTypes.START_CREATE_PROJECT:
      return {
        ...state,
        creatingProject: (action as StartCreateProjectAction).payload,
        createProjectError: null
      };
    case ActionTypes.FINISH_CREATE_PROJECT:
      return {
        ...state,
        creatingProject: null,
        creatingProjectError: null,
        projects: [
          ...state.projects,
          (action as FinishCreateProjectAction).payload
        ]
      };
    case ActionTypes.ERROR_CREATE_PROJECT:
      return {
        ...state,
        creatingProject: null,
        createProjectError: (action as ErrorCreateProjectAction).payload
      };
    case ActionTypes.START_EDIT_PROJECT:
      return {
        ...state,
        editingProject: (action as StartEditProjectAction).payload,
        editProjectError: null
      };
    case ActionTypes.FINISH_EDIT_PROJECT:
      return {
        ...state,
        editingProject: null,
        editProjectError: null,
        projects: state.projects.map(project => {
          if (project.id === state.editingProject!.id) {
            return Object.assign({}, state.editingProject!);
          } else {
            return project;
          }
        })
      };
    case ActionTypes.ERROR_EDIT_PROJECT:
      return {
        ...state,
        editingProject: null,
        editProjectError: (action as ErrorCreateProjectAction).payload
      };
    case ActionTypes.START_DELETE_PROJECT:
      return {
        ...state,
        deletingProject: (action as StartDeleteProjectAction).payload,
        deleteProjectError: null
      };
    case ActionTypes.FINISH_DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(
          project => project.id !== state.deletingProject
        ),
        deletingProject: null
      };
    case ActionTypes.ERROR_DELETE_PROJECT:
      return {
        ...state,
        deletingProject: null,
        deleteProjectError: (action as ErrorDeleteProjectAction).payload
      };
    case ActionTypes.START_CREATE_COLLECTION:
      return {
        ...state,
        creatingCollection: (action as StartCreateCollectionAction).payload,
        createCollectionError: null
      };
    case ActionTypes.FINISH_CREATE_COLLECTION:
      return {
        ...state,
        creatingCollection: null,
        projects: state.projects.map(project => {
          if (project.id === state.creatingCollection!.projectId) {
            return {
              ...project,
              collections: [
                ...project.collections,
                state.creatingCollection!.collection
              ]
            };
          } else {
            return project;
          }
        })
      };
    case ActionTypes.ERROR_CREATE_COLLECTION:
      return {
        ...state,
        creatingCollection: null,
        createCollectionError: (action as ErrorCreateCollectionAction).payload
      };
    case ActionTypes.START_EDIT_COLLECTION:
      return {
        ...state,
        editingCollection: (action as StartEditCollectionAction).payload,
        editCollectionError: null
      };
    case ActionTypes.FINISH_EDIT_COLLECTION:
      return {
        ...state,
        editingCollection: null,
        projects: state.projects.map(project => {
          if (project.id === state.editingCollection!.projectId) {
            return {
              ...project,
              collections: project.collections.map((collection, index) => {
                if (index === state.editingCollection!.index) {
                  return Object.assign({}, state.editingCollection!.collection);
                } else {
                  return collection;
                }
              })
            };
          } else {
            return project;
          }
        })
      };
    case ActionTypes.ERROR_EDIT_COLLECTION:
      return {
        ...state,
        editingCollection: null,
        editCollectionError: (action as ErrorEditCollectionAction).payload
      };
    case ActionTypes.START_DELETE_COLLECTION:
      return {
        ...state,
        deletingCollection: (action as StartDeleteCollectionAction).payload,
        deleteCollectionError: null
      };
    case ActionTypes.FINISH_DELETE_COLLECTION:
      return {
        ...state,
        deletingCollection: null,
        projects: state.projects.map(project => {
          if (project.id === state.deletingCollection!.projectId) {
            return {
              ...project,
              collections: project.collections.filter((collection, index) => {
                return index !== state.deletingCollection!.index;
              })
            };
          } else {
            return project;
          }
        })
      };
    case ActionTypes.ERROR_DELETE_COLLECTION:
      return {
        ...state,
        deletingCollection: null,
        deleteCollectionError: (action as ErrorDeleteCollectionAction).payload
      };
  }
  return state;
}

export default projects;
