import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

import { RootState } from "../store/state";
import { connect } from "react-redux";
import { Project, MachineType } from "../store/projects/types";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {
  dataSizeMemory,
  dataSizeMemoryAfterGrowth,
  dataSizeDisk,
  dataSizeDiskAfterGrowth,
  totalDiskSpaceUncompressed,
  peakLoadSizeUncompressed,
  peakLoadSizeUncompressedUpdated,
  totalDiskSpaceCompressed,
  peakLoadSizeCompressed,
  peakLoadSizeCompressedUpdated,
  totalDiskSpaceCompressedAfterGrowth,
  peakLoadSizeCompressedUpdatedAfterGrowth,
  peakLoadSizeCompressedAfterGrowth,
  peakLoadSizeUncompressedUpdatedAfterGrowth,
  totalDiskSpaceUncompressedAfterGrowth,
  peakLoadSizeUncompressedAfterGrowth,
  machineMemory
} from "../functions/project";
import {
  memoryIncludingReplicas,
  memoryIncludingReplicasAndCaches,
  availableMachineMemory,
  numberOfMachines,
  numberOfLicenses,
  numberOfMachinesAfterGrowth,
  numberOfLicensesAfterGrowth,
  memoryIncludingReplicasAfterGrowth,
  memoryIncludingReplicasAndCachesAfterGrowth
} from "../functions/project";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import { ThunkDispatch } from "redux-thunk";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Action } from "redux";
import { editProject } from "../store/projects/actions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { AWSInstanceSelect } from "./AWSInstanceSelect";
import TextField from "@material-ui/core/TextField";
import { FontWeightProperty, WhiteSpaceProperty } from "csstype";
import { InputAdornment, MenuItem, Switch, Input } from "@material-ui/core";
import withAuth from "../hoc/withAuth";
import { gib } from "../format/size";
import PercentageOfTextField from "./PercentageOfTextField";

const styles = (theme: Theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  bigCell: {
    fontSize: "1em",
    fontWeight: "bold" as FontWeightProperty,
    whiteSpace: "nowrap" as WhiteSpaceProperty
  },
  advanced: {
    textAlign: "right" as any
  },
  inlineInput: {
    width: "40px"
  },
  noBrCell: {
    whiteSpace: "nowrap" as WhiteSpaceProperty
  }
});

interface SummaryProps extends WithStyles<typeof styles> {
  auth?: any;
  project: Project;
  editProject: (accessToken: string, project: Project) => void;
}

const Summary = (props: SummaryProps) => {
  const {
    auth,
    classes,
    project,
    editProject: authenticatedEditProject
  } = props;

  const editProject = async (project: Project) => {
    const accessToken = await auth.getAccessToken();
    authenticatedEditProject(accessToken, project);
  };

  const handleNumberChange = (
    property:
      | "systemMemory"
      | "rocksDBWriteCache"
      | "queryResultCache"
      | "memoryForReplicas"
      | "sizeHotData"
      | "genericMachineMemory"
      | "datacenters"
      | "growth"
      | "numberOfGrowthUnits"
      | "growthUnit"
      | "lsmTreeOverhead"
      | "compressionRate"
      | "peakSpaceFactorUncompressed"
      | "peakSpaceFactorCompressed"
      | "updatedData"
  ) => {
    return async (event: React.ChangeEvent<HTMLInputElement>) => {
      return await changeNumber(
        property,
        parseInt((event.target as HTMLInputElement).value, 10) || 0
      );
    };
  };

  const changeNumber = async (
    property:
      | "systemMemory"
      | "rocksDBWriteCache"
      | "queryResultCache"
      | "memoryForReplicas"
      | "sizeHotData"
      | "genericMachineMemory"
      | "datacenters"
      | "growth"
      | "numberOfGrowthUnits"
      | "growthUnit"
      | "lsmTreeOverhead"
      | "compressionRate"
      | "peakSpaceFactorUncompressed"
      | "peakSpaceFactorCompressed"
      | "updatedData",
    value: number
  ) => {
    let p = {
      ...project,
      [property]: value
    };
    return await editProject(p);
  };

  const machineMemoryValue = machineMemory(project);

  let machineMemoryConfig;
  if (project.machineType === MachineType.AWS) {
    machineMemoryConfig = (
      <AWSInstanceSelect
        value={project.awsInstanceType}
        disabled={project.machineType !== MachineType.AWS}
        onChange={awsInstanceType => {
          if (awsInstanceType === undefined) {
            project.awsInstanceType = "";
          } else {
            project.awsInstanceType = awsInstanceType!;
          }
          editProject(project);
        }}
      />
    );
  } else {
    machineMemoryConfig = (
      <TextField
        label="Generic Memory (in GiB)"
        value={project.genericMachineMemory}
        disabled={project.machineType !== MachineType.Generic}
        onChange={handleNumberChange("genericMachineMemory")}
        margin="normal"
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">GiB</InputAdornment>
        }}
      />
    );
  }

  const [advanced, setAdvanced] = useState(false);

  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <div className={classes.advanced}>
          <FormControlLabel
            control={
              <Switch
                checked={advanced}
                onChange={event => setAdvanced(event.target.checked)}
                value="checkedB"
                color="primary"
              />
            }
            label="Advanced"
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.bigCell} />
              <TableCell className={classes.bigCell} align="right">
                Now
              </TableCell>
              <TableCell className={classes.bigCell} align="right">
                After Growth
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.bigCell}>Machines</TableCell>
              <TableCell className={classes.bigCell} align="right">
                {numberOfMachines(project)}
              </TableCell>
              <TableCell className={classes.bigCell} align="right">
                {numberOfMachinesAfterGrowth(project)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.bigCell}>Licenses</TableCell>
              <TableCell className={classes.bigCell} align="right">
                {numberOfLicenses(project)}
              </TableCell>
              <TableCell className={classes.bigCell} align="right">
                {numberOfLicensesAfterGrowth(project)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.bigCell}>
                Number of collections
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {project.collections.length}
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {project.collections.length}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.bigCell}>
                Size dataset in memory
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {gib(dataSizeMemory(project))}
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {gib(dataSizeMemoryAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.bigCell}>
                Size dataset on disk
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {gib(dataSizeDisk(project))}
              </TableCell>
              <TableCell align="right" className={classes.bigCell}>
                {gib(dataSizeDiskAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} />
            </TableRow>
            <TableRow>
              <TableCell>
                Memory including replicas to keep {project.sizeHotData}% of your
                data set in memory
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(memoryIncludingReplicas(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(memoryIncludingReplicasAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Memory including replicas and caches to keep&nbsp;
                {project.sizeHotData}% of your data set in memory
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(memoryIncludingReplicasAndCaches(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(memoryIncludingReplicasAndCachesAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Total space on disk needed without Snappy compression
                <Typography variant="subtitle1">
                  This is the amount of disk space needed including the overhead
                  of the LSM tree levels.
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(totalDiskSpaceUncompressed(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(totalDiskSpaceUncompressedAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Space needed at peak without compression if the whole dataset
                would be loaded at once
                <Typography variant="subtitle1">
                  If there is a lower amount of free disk space it will just
                  slow down the ingestion rate but not stop such an operation.
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeUncompressed(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeUncompressedAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Space needed at peak without compression if{" "}
                {project.updatedData}% of the dataset would be update in one
                step
                <Typography variant="subtitle1">
                  If there is a lower amount of free disk space it will just
                  slow down the ingestion rate but not stop such an operation.
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeUncompressedUpdated(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeUncompressedUpdatedAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Total space on disk needed with Snappy compression
                <Typography variant="subtitle1">
                  This is the acchievable compression with Snappy. Please, keep
                  in mind that compression will have an inpact on the write
                  performance. As thumb rule you can excpet an 15% decrease.
                  This is only a very rough estimation because it depends
                  heavily on load, available CPUs, disk speed, ...
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(totalDiskSpaceCompressed(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(totalDiskSpaceCompressedAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Space needed at peak with compression if the whole dataset would
                be loaded at once
                <Typography variant="subtitle1">
                  If there is a lower amount of free disk space it will just
                  slow down the ingestion rate but not stop such an operation.
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeCompressed(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeCompressedAfterGrowth(project))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Space needed at peak with compression if {project.updatedData}%
                of the dataset would be update in one step
                <Typography variant="subtitle1">
                  If there is a lower amount of free disk space it will just
                  slow down the ingestion rate but not stop such an operation.
                </Typography>
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeCompressedUpdated(project))}
              </TableCell>
              <TableCell align="right" className={classes.noBrCell}>
                {gib(peakLoadSizeCompressedUpdatedAfterGrowth(project))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={24} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={1}>
              <Typography variant="h4" component="h2">
                Machine Settings
              </Typography>
              <FormGroup>
                <FormControl component={"fieldset" as "div"}>
                  <RadioGroup
                    row
                    value={project.machineType}
                    onChange={event => {
                      const target = event.target as HTMLInputElement;
                      project.machineType = target.value as MachineType;
                      editProject(project);
                    }}
                  >
                    <FormControlLabel
                      value={MachineType.AWS}
                      control={<Radio />}
                      label="AWS Instance"
                    />
                    <FormControlLabel
                      value={MachineType.Generic}
                      control={<Radio />}
                      label="Generic"
                    />
                  </RadioGroup>
                </FormControl>
                {machineMemoryConfig}
              </FormGroup>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Availabe memory per machine</TableCell>
                    <TableCell align="right">
                      {gib(availableMachineMemory(project))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={1}>
              <Typography variant="h4" component="h2">
                Cluster settings
              </Typography>
              <FormControl component={"fieldset" as "div"}>
                <FormLabel component={"legend" as "code"}>
                  Number of data centers
                </FormLabel>
                <RadioGroup
                  aria-label="Data centers"
                  name="datacenters"
                  row
                  value={project.datacenters.toString()}
                  onChange={event => {
                    const target = event.target as HTMLInputElement;
                    project.datacenters = parseInt(target.value, 10);
                    editProject(project);
                  }}
                >
                  {Array.from({ length: 5 }).map((v, index) => {
                    index = index + 1;
                    return (
                      <FormControlLabel
                        key={index}
                        value={index.toString()}
                        control={<Radio />}
                        label={index}
                      />
                    );
                  })}
                </RadioGroup>
                <FormLabel component={"legend" as "code"}>
                  Replication Factor
                </FormLabel>
                <RadioGroup
                  aria-label="Replication Factor"
                  name="replicationFactor"
                  row
                  value={project.replicationFactor.toString()}
                  onChange={event => {
                    const target = event.target as HTMLInputElement;
                    project.replicationFactor = parseInt(target.value, 10);
                    editProject(project);
                  }}
                >
                  {Array.from({ length: 7 }).map((v, index) => {
                    index = index + 1;
                    return (
                      <FormControlLabel
                        key={index}
                        value={index.toString()}
                        control={<Radio />}
                        label={index}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={1}>
              <Typography variant="h4" component="h2">
                Dataset
              </Typography>
              <FormGroup>
                <TextField
                  label="Size of hot data"
                  value={project.sizeHotData}
                  onChange={handleNumberChange("sizeHotData")}
                  margin="normal"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }}
                />
                <div>
                  The data set is expected to grow by{" "}
                  <Input
                    value={project.growth}
                    onChange={handleNumberChange("growth")}
                    type="number"
                    className={classes.inlineInput}
                  />{" "}
                  % each{" "}
                  <TextField
                    margin="none"
                    select
                    value={project.growthUnit}
                    onChange={event =>
                      editProject({
                        ...project,
                        growthUnit: event.target
                          .value as (typeof project.growthUnit)
                      })
                    }
                  >
                    {["week", "month", "year"].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>{" "}
                  in a{" "}
                  <Input
                    value={project.numberOfGrowthUnits}
                    onChange={handleNumberChange("numberOfGrowthUnits")}
                    type="number"
                    className={classes.inlineInput}
                  />{" "}
                  {project.growthUnit}s observation period.
                </div>
              </FormGroup>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={1}>
              <Typography variant="h4" component="h2">
                Additional information/Data manipulation
              </Typography>
              <FormGroup>
                <TextField
                  label="Memory that is used for replicas"
                  value={project.memoryForReplicas}
                  onChange={handleNumberChange("memoryForReplicas")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }}
                  margin="normal"
                  type="number"
                  helperText="This value defines the % of leader memory which is needed for a replica.  If you have a mostely append data use case it should be 10%. If you do mostely updates it should be 50% and if you want to do dirty reads it should be 100%."
                />
                {advanced ? (
                  <TextField
                    label="LSM tree overhead"
                    value={project.lsmTreeOverhead}
                    onChange={handleNumberChange("lsmTreeOverhead")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      )
                    }}
                    margin="normal"
                    type="number"
                    helperText="This is the approx. overhead which is needed to handle the different levels of the LSM tree."
                  />
                ) : null}
                {advanced ? (
                  <TextField
                    label="Compression rate"
                    value={project.compressionRate}
                    onChange={handleNumberChange("compressionRate")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      )
                    }}
                    margin="normal"
                    type="number"
                    helperText="This is the approx. compression rate which is achievable with Snappy."
                  />
                ) : null}
                {advanced ? (
                  <TextField
                    label="Peak factor for space on disk without compression"
                    value={project.peakSpaceFactorUncompressed}
                    onChange={handleNumberChange("peakSpaceFactorUncompressed")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      )
                    }}
                    margin="normal"
                    type="number"
                    helperText="This is the peak factor for the required space on disk. This is more a thumb rule as a exact value because it depends on the size of the objects, number of collections."
                  />
                ) : null}
                {advanced ? (
                  <TextField
                    label="Peak factor for space on disk with compression"
                    value={project.peakSpaceFactorCompressed}
                    onChange={handleNumberChange("peakSpaceFactorCompressed")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      )
                    }}
                    margin="normal"
                    type="number"
                    helperText="This is the peak factor for the required space on disk. This is more a thumb rule as a exact value because it depends on the size of the objects, number of collections, ..."
                  />
                ) : null}
                <TextField
                  label="Percentage of the data which will be update at once"
                  value={project.updatedData}
                  onChange={handleNumberChange("updatedData")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }}
                  margin="normal"
                  type="number"
                  helperText="This is the percentage of data which will be updated in maximum in one step."
                />
              </FormGroup>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={1}>
              <Typography variant="h4" component="h2">
                Caches
              </Typography>
              <FormGroup>
                {advanced ? (
                  <PercentageOfTextField
                    percentageOf={machineMemoryValue}
                    onChange={value => changeNumber("systemMemory", value)}
                    label="Base system memory requirements"
                    value={project.systemMemory}
                    helperText="This parameter defines the part of the memory which is reserved for the operation system. It is limited to a maximum of 5GB."
                  />
                ) : null}
                {advanced ? (
                  <PercentageOfTextField
                    percentageOf={machineMemoryValue}
                    onChange={value => changeNumber("rocksDBWriteCache", value)}
                    label="RocksDB write cache in % of system memory"
                    value={project.rocksDBWriteCache}
                    helperText="This is the cache for writes on RocksDB level. It is only for writes operation and, therefore, is excluded from the available memory because it is not available for read operations. It is limited to a maximum of 30GB."
                  />
                ) : null}
                <PercentageOfTextField
                  percentageOf={machineMemoryValue}
                  onChange={value => changeNumber("queryResultCache", value)}
                  label="Query result cache (Coordinator and DBServer)"
                  value={project.queryResultCache}
                  helperText="This is the cache for query results. It is only a temporal cache and, therefore, is excluded from the available memory because it does not improve read operations. It is limited to a maximum of 20GB."
                />
              </FormGroup>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, Action>
): Pick<SummaryProps, "editProject"> => {
  return {
    editProject: (accessToken, project) =>
      dispatch(editProject(accessToken, project))
  };
};

const mapStateToProps = (state: RootState, props: any) => {
  return {
    project: state.projects.projects.find(
      project => project.id === props.id
    ) as Project
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(withStyles(styles)(Summary)));
