import { Field, FieldType, IndexType } from "../store/projects/types";

export function calculateObjectSize(fields: Field[]) {
  if (fields.length === 0) {
    // type empty object
    return 1;
  }

  let objectOverhead = 0;
  if (fields.length < 256) {
    objectOverhead =
      1 + // vpack type
      2 + // bytelength+nritems
      fields.length; // indextable;
  } else if (fields.length < 65536) {
    objectOverhead =
      1 + // vpack type
      4 + // bytelength+nritems
      fields.length * 2; // indextable;
  } else if (fields.length < 4294967296) {
    objectOverhead =
      1 + // vpack type
      8 + // bytelength+nritems
      fields.length * 4; // indextable;
  } else {
    throw new Error("Out of bounds");
  }

  return fields.reduce((sum, field) => sum + fieldSize(field), objectOverhead);
}

export function calculateArraySize(field: Field) {
  const arrayLength = field.length;
  let arrayOverhead = 0;
  if (arrayLength < 256) {
    arrayOverhead =
      1 + // vpack type
      2 + // bytelength+nritems
      arrayLength; // indextable;
  } else if (arrayLength < 65536) {
    arrayOverhead =
      1 + // vpack type
      4 + // bytelength+nritems
      arrayLength * 2; // indextable;
  } else if (arrayLength < 4294967296) {
    arrayOverhead =
      1 + // vpack type
      8 + // bytelength+nritems
      arrayLength * 4; // indextable;
  } else {
    throw new Error("Out of bounds");
  }

  return arrayOverhead + rawFieldSize(field) * arrayLength;
}

export function rawFieldSize(field: Field): number {
  switch (field.type) {
    case FieldType.STRING:
      return field.length;
    case FieldType.DATESTRING:
      return 25;
    case FieldType.DATE:
      return 9;
    case FieldType.NULL:
      return 1;
    case FieldType.BOOL:
      return 1;
    case FieldType.INT8:
      return 2;
    case FieldType.INT16:
      return 3;
    case FieldType.INT24:
      return 4;
    case FieldType.INT32:
      return 5;
    case FieldType.INT40:
      return 6;
    case FieldType.INT48:
      return 7;
    case FieldType.INT56:
      return 8;
    case FieldType.INT64:
      return 9;
    case FieldType.DOUBLE:
      return 9;
    case FieldType.OBJECT:
      return calculateObjectSize(field.object!);
    case FieldType.ARRAY:
      return calculateArraySize(field.array!);
  }
  throw new Error("Unhandled type " + field.type);
}

function fieldOverhead(field: Field): number {
  switch (field.name) {
    case "_key":
    case "_to":
    case "_id":
    case "_rev":
    case "_from":
      return 2;
    default:
      let overhead = 1;
      if (field.name.length >= 128) {
        overhead = 8;
      }
      return field.name.length + overhead;
  }
}

export function fieldIndexSize(field: Field): number {
  if (field.index !== undefined) {
    switch (field.index) {
      case IndexType.UNIQUE:
        return 16 + fieldSize(field) + 4;
      case IndexType.NON_UNIQUE:
        return 16 + fieldSize(field) + 2 + 4;
    }
  }
  return 0;
}

export function fieldSize(field: Field): number {
  const baseSize = rawFieldSize(field);
  const overhead = fieldOverhead(field);

  return baseSize + overhead;
}

export const fieldNameValid = (name: string) => name.length > 0;
