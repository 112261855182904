import React from "react";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";

const styles = (theme: Theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  fab: {
    margin: theme.spacing.unit
  }
});

type CollectionTypeForm = "document" | "edge";

interface CollectionFormPropertiesProps extends WithStyles<typeof styles> {
  name: string;
  setName: (name: string) => void;
  type: "document" | "edge";
  setType: (type: CollectionTypeForm) => void;
  numberOfDocuments: number;
  setNumberOfDocuments: (type: number) => void;
  numberOfVertices: number;
  setNumberOfVertices: (type: number) => void;
}

const CollectionFormProperties = (props: CollectionFormPropertiesProps) => {
  const {
    classes,
    name,
    setName,
    type,
    setType,
    numberOfDocuments,
    setNumberOfDocuments,
    numberOfVertices,
    setNumberOfVertices
  } = props;

  const setFormValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "type":
        setType(value as CollectionTypeForm);
        break;
      case "numberOfDocuments":
        setNumberOfDocuments(parseInt(value, 10) || 0);
        break;
      case "numberOfVertices":
        setNumberOfVertices(parseInt(value, 10) || 0);
        break;
    }
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={6}>
        <TextField
          required
          id="name"
          name="name"
          label="Name"
          value={name}
          onChange={setFormValue}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="type">Type</InputLabel>
          <Select
            value={type}
            onChange={setFormValue}
            inputProps={{
              name: "type",
              id: "type"
            }}
          >
            <MenuItem value={"document"}>Document</MenuItem>
            <MenuItem value={"edge"}>Edge</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <TextField
          required
          id="numberOfDocuments"
          type="number"
          name="numberOfDocuments"
          label="Number of documents"
          value={numberOfDocuments}
          onChange={setFormValue}
        />
      </Grid>
      {type === "edge" ? (
        <Grid item xs={2}>
          <TextField
            required
            id="numberOfVertices"
            type="number"
            name="numberOfVertices"
            label="Number of vertices"
            value={numberOfVertices}
            onChange={setFormValue}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(CollectionFormProperties);
