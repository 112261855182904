import { createAction } from "redux-actions";
import {
  Project,
  ActionTypes,
  Collection,
  CreateCollectionRequest,
  EditCollectionRequest
} from "./types";
import { Dispatch } from "redux";
import { DeleteCollectionRequest } from "./actiontypes";
import history from "../../history";

const startFetchProjects = createAction(ActionTypes.START_FETCH_PROJECTS);
const finishFetchProjects = createAction<Project[]>(
  ActionTypes.FINISH_FETCH_PROJECTS
);
const errorFetchProjects = createAction<Error>(
  ActionTypes.ERROR_FETCH_PROJECTS
);

const startCreateProject = createAction<Project>(
  ActionTypes.START_CREATE_PROJECT
);
const finishCreateProject = createAction<Project>(
  ActionTypes.FINISH_CREATE_PROJECT
);
const errorCreateProject = createAction<Error>(
  ActionTypes.ERROR_CREATE_PROJECT
);

const startEditProject = createAction<Project>(ActionTypes.START_EDIT_PROJECT);
const finishEditProject = createAction(ActionTypes.FINISH_EDIT_PROJECT);
const errorEditProject = createAction<Error>(ActionTypes.ERROR_EDIT_PROJECT);

const startDeleteProject = createAction<string>(
  ActionTypes.START_DELETE_PROJECT
);
const finishDeleteProject = createAction(ActionTypes.FINISH_DELETE_PROJECT);
const errorDeleteProject = createAction<Error>(
  ActionTypes.ERROR_DELETE_PROJECT
);

const startCreateCollection = createAction<CreateCollectionRequest>(
  ActionTypes.START_CREATE_COLLECTION
);
const finishCreateCollection = createAction(
  ActionTypes.FINISH_CREATE_COLLECTION
);
const errorCreateCollection = createAction<Error>(
  ActionTypes.ERROR_CREATE_COLLECTION
);

const startEditCollection = createAction<EditCollectionRequest>(
  ActionTypes.START_EDIT_COLLECTION
);
const finishEditCollection = createAction(ActionTypes.FINISH_EDIT_COLLECTION);
const errorEditCollection = createAction<Error>(
  ActionTypes.ERROR_EDIT_COLLECTION
);

const startDeleteCollection = createAction<DeleteCollectionRequest>(
  ActionTypes.START_DELETE_COLLECTION
);
const finishDeleteCollection = createAction(
  ActionTypes.FINISH_DELETE_COLLECTION
);
const errorDeleteCollection = createAction<Error>(
  ActionTypes.ERROR_DELETE_COLLECTION
);

export function createProject(accessToken: string, project: Project) {
  return async function(dispatch: Dispatch) {
    dispatch(startCreateProject(project));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify(project)
        }
      );
      const backendProject: Project = await response.json();
      dispatch(finishCreateProject(backendProject));
      history.push(`/projects/${backendProject.id}`);
    } catch (e) {
      dispatch(errorCreateProject(e));
    }
  };
}

export function editProject(accessToken: string, project: Project) {
  return async function(dispatch: Dispatch) {
    dispatch(startEditProject(project));
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects/${project.id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          method: "PUT",
          body: JSON.stringify(project)
        }
      );
      dispatch(finishEditProject());
    } catch (e) {
      dispatch(errorEditProject(e));
    }
  };
}

export function deleteProject(accessToken: string, projectId: string) {
  return async function(dispatch: Dispatch) {
    dispatch(startDeleteProject(projectId));
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects/${projectId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          method: "DELETE"
        }
      );
      dispatch(finishDeleteProject());
    } catch (e) {
      dispatch(errorDeleteProject(e));
    }
  };
}

export function createCollection(
  accessToken: string,
  projectId: string,
  collection: Collection
) {
  return async function(dispatch: Dispatch) {
    dispatch(startCreateCollection({ projectId, collection }));
    try {
      await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/projects/${projectId}/collections`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(collection),
          method: "POST"
        }
      );
      dispatch(finishCreateCollection());
    } catch (e) {
      dispatch(errorCreateCollection(e));
    }
  };
}

export function editCollection(
  accessToken: string,
  projectId: string,
  index: number,
  collection: Collection
) {
  return async function(dispatch: Dispatch) {
    dispatch(startEditCollection({ projectId, index, collection }));
    try {
      await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/projects/${projectId}/collections/${index}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(collection),
          method: "PUT"
        }
      );
      dispatch(finishEditCollection());
    } catch (e) {
      dispatch(errorEditCollection(e));
    }
  };
}

export function deleteCollection(
  accessToken: string,
  projectId: string,
  index: number
) {
  return async function(dispatch: Dispatch) {
    dispatch(startDeleteCollection({ projectId, index }));
    try {
      await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/projects/${projectId}/collections/${index}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json"
          },
          method: "DELETE"
        }
      );
      dispatch(finishDeleteCollection());
    } catch (e) {
      dispatch(errorDeleteCollection(e));
    }
  };
}

export function fetchProjects(accessToken: string) {
  return async function(dispatch: Dispatch) {
    dispatch(startFetchProjects());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects`,
        {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        }
      );
      const projects = await response.json();
      dispatch(finishFetchProjects(projects));
    } catch (e) {
      dispatch(errorFetchProjects(e));
    }
  };
}
