import React from "react";

import { Button } from "@material-ui/core";
import withAuth from "../hoc/withAuth";

interface LogoutButtonProps {
  auth?: any;
}

function LogoutButton(props: LogoutButtonProps) {
  return (
    <Button color="inherit" onClick={() => props.auth.logout("/")}>
      Logout
    </Button>
  );
}

export default withAuth(LogoutButton);
