import React, { useState } from "react";
import { connect } from "react-redux";
import { Project, Collection } from "../store/projects/types";
import { RootState } from "../store/state";
import CollectionList from "./CollectionList";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Theme, WithStyles, withStyles, Button } from "@material-ui/core";
import CollectionDialog from "./CollectionDialog";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { createCollection, editCollection } from "../store/projects/actions";
import CollectionImportDialog from "./CollectionImportDialog";
import withAuth from "../hoc/withAuth";

const styles = (theme: Theme) => ({
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  buttonRow: {
    marginBottom: "10px"
  }
});

interface CollectionsProps extends WithStyles<typeof styles> {
  auth?: any;
  project: Project;
  createCollection: (
    accessToken: string,
    projectId: string,
    collection: Collection
  ) => Promise<any>;
  editCollection: (
    accessToken: string,
    projectId: string,
    index: number,
    collection: Collection
  ) => Promise<any>;
}

const Collections = (props: CollectionsProps) => {
  const { auth, project, editCollection, createCollection } = props;

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [fromTemplateDialogOpen, setFromTemplateDialogOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState();

  const saveCollection = async function(
    projectId: string,
    collection: Collection
  ) {
    const accessToken = await auth.getAccessToken();
    let promise;
    if (selectedCollection !== undefined) {
      promise = editCollection(
        accessToken,
        projectId,
        selectedCollection,
        collection
      );
    } else {
      promise = createCollection(accessToken, projectId, collection);
    }
    promise.then(result => {
      setEditDialogOpen(false);
      setFromTemplateDialogOpen(false);
      setSelectedCollection(undefined);
    });
  };

  const onEditRequest = (index: number) => {
    setSelectedCollection(index);
    setEditDialogOpen(true);
  };

  const onEdit = async (index: number, collection: Collection) => {
    const accessToken = await auth.getAccessToken();
    editCollection(accessToken, project.id!, index, collection);
  };

  let dialog;
  if (editDialogOpen) {
    dialog = (
      <CollectionDialog
        collection={project.collections[selectedCollection]}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={collection => saveCollection(project.id!, collection)}
      />
    );
  } else if (fromTemplateDialogOpen) {
    dialog = (
      <CollectionImportDialog
        open={fromTemplateDialogOpen}
        onClose={() => setFromTemplateDialogOpen(false)}
        onSave={collection => saveCollection(project.id!, collection)}
      />
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        Collections
      </Typography>
      <div className={props.classes.buttonRow}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            setSelectedCollection(undefined);
            setEditDialogOpen(true);
          }}
        >
          Add
          <AddIcon />
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setFromTemplateDialogOpen(true)}
        >
          From template document
          <AddIcon />
        </Button>
      </div>
      <Paper>
        <CollectionList
          project={project}
          onEditRequest={onEditRequest}
          onEdit={onEdit}
        />
      </Paper>
      {dialog}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  return {
    project: state.projects.projects.find(
      project => project.id === props.id
    ) as Project
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, Action>
): Pick<CollectionsProps, "createCollection" | "editCollection"> => {
  return {
    createCollection: (
      accessToken: string,
      projectId: string,
      collection: Collection
    ) => dispatch(createCollection(accessToken, projectId, collection)),
    editCollection: (
      accessToken: string,
      projectId: string,
      index: number,
      collection: Collection
    ) => dispatch(editCollection(accessToken, projectId, index, collection))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(withStyles(styles)(Collections)));
