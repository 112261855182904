import React, { useRef, useState } from "react";
import { Collection } from "../store/projects/types";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { dataSizeMemory, documentSize } from "../functions/collection";
import filesize from "filesize";
import TextField from "@material-ui/core/TextField";

const styles = (theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: "pointer"
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200]
      }
    },
    iconWrapper: {
      display: "inline-block"
    },
    // we have a row level callback...placeholder will fill up space so that
    // the inlinewrapper only wraps the textfield and we can check if that was mousepressed
    // and not the whole cell (which might be large)
    helplessSpacer: {
      display: "inline-block",
      width: "100%"
    },
    inlineEditWrapper: {
      display: "inline-block"
    }
  });

interface CollectionRowProps extends WithStyles<typeof styles> {
  collection: Collection;
  collectionIndex: number;
  onEdit: (collection: Collection) => void;
  onDeleteRequest: (index: number) => void;
  onEditRequest: (index: number) => void;
}

const CollectionRow = (props: CollectionRowProps) => {
  const {
    collection,
    onEdit,
    onEditRequest,
    onDeleteRequest,
    classes,
    collectionIndex
  } = props;
  const deleteButtonRef = useRef(null);
  const inlineEditRef = useRef(null);
  const [numberOfDocuments, setNumberOfDocuments] = useState(
    props.collection.documents.toString()
  );

  const error = isNaN(parseInt(numberOfDocuments, 10));
  let additionalProps: any = {};
  if (error) {
    additionalProps["error"] = true;
  }

  const setCheckedNumberOfDocuments = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setNumberOfDocuments(value);

    const numberOfDocuments = parseInt(value, 10);
    if (!isNaN(numberOfDocuments) && numberOfDocuments > 0) {
      onEdit({
        ...collection,
        documents: numberOfDocuments
      });
    }
  };

  return (
    <TableRow
      className={classNames(classes.tableRow, classes.tableRowHover)}
      onClick={(event: React.MouseEvent) => {
        // the row callback will trigger everywhere in the row
        // check if we hit one of the buttons and ignore the event in case of this
        let current: Node | undefined = event.target as Node;

        while (current && current !== event.currentTarget) {
          if (
            current === deleteButtonRef.current ||
            current === inlineEditRef.current
          ) {
            return;
          }
          current = current.parentNode as Node | undefined;
        }
        onEditRequest(collectionIndex);
      }}
    >
      <TableCell component="th" scope="row">
        {collection.name}
      </TableCell>
      <TableCell align="right">
        <div className={classes.helplessSpacer} />
        <div className={classes.inlineEditWrapper} ref={inlineEditRef}>
          <TextField
            id="outlined-bare"
            margin="normal"
            variant="outlined"
            type="number"
            value={numberOfDocuments}
            onChange={setCheckedNumberOfDocuments}
            {...additionalProps}
          />
        </div>
      </TableCell>
      <TableCell align="right">{collection.fields.length}</TableCell>
      <TableCell align="right">
        {filesize(documentSize(collection), { standard: "iec" })}
      </TableCell>
      <TableCell align="right">
        {filesize(dataSizeMemory(collection), { standard: "iec" })}
      </TableCell>
      <TableCell align="right">
        <div
          ref={deleteButtonRef}
          className={classNames(classes.iconWrapper)}
          onClick={() => onDeleteRequest(collectionIndex)}
        >
          <IconButton aria-label="Delete" color="primary">
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(CollectionRow);
