import history from "../history";
import auth0, { WebAuth } from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";

export default class Auth {
  private accessToken: string | null;
  private idToken: string | null;
  private expiresAt: number;
  private auth0: WebAuth;
  private tokenRenewalTimeout: any;
  private roles: string[] | null;
  private project: string | null;

  constructor() {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    this.roles = [];
    this.project = null;
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientID,
      redirectUri: AUTH_CONFIG.callbackUrl,
      audience: AUTH_CONFIG.audience,
      responseType: "token id_token",
      scope: "openid"
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);

    this.getExpiryDate = this.getExpiryDate.bind(this);
    this.getProject = this.getProject.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.isAdmin = this.isAdmin.bind(this);

    // constant reloads...save authResult in development
    if (process.env.NODE_ENV === "development") {
      const debugAuthResult = localStorage.getItem("DebugAuthResult");
      if (debugAuthResult) {
        try {
          const parsedDebugAuthResult = JSON.parse(debugAuthResult);
          const session = {
            accessToken: parsedDebugAuthResult.accessToken,
            idToken: parsedDebugAuthResult.idToken,
            expiresIn:
              (parsedDebugAuthResult.expiresAt - new Date().getTime()) / 1000,
            idTokenPayload: {
              "https://datasizer.arangodb.com/roles":
                parsedDebugAuthResult.roles,
              "https://datasizer.arangodb.com/project":
                parsedDebugAuthResult.project
            }
          };
          // will also trigger renewal
          this.setSession(session, false);
          return;
        } catch (e) {
          // JSON parse error
        }
      }
    }

    this.scheduleRenewal();
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace("/");
        console.log(err);
      }
    });
  }

  getAccessToken() {
    return Promise.resolve(this.accessToken);
  }

  getIdToken() {
    return this.idToken;
  }

  getRoles() {
    return this.roles;
  }

  getProject() {
    return this.project;
  }

  isAdmin() {
    return this.roles && this.roles.includes("admin");
  }

  setSession(authResult: any, redirect: boolean = true) {
    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    this.roles =
      authResult.idTokenPayload["https://datasizer.arangodb.com/roles"] || [];
    this.project =
      authResult.idTokenPayload["https://datasizer.arangodb.com/project"] || "";

    // in development we have constant reloads...save result in localStorage
    if (process.env.NODE_ENV === "development") {
      localStorage.setItem(
        "DebugAuthResult",
        JSON.stringify({
          accessToken: this.accessToken,
          idToken: this.idToken,
          expiresAt: this.expiresAt,
          roles: this.roles,
          project: this.project
        })
      );
    }

    this.scheduleRenewal();

    // navigate to the home route
    if (redirect) {
      history.replace("/");
    }
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        console.log(err);
      }
    });
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout);

    this.auth0.logout({
      returnTo: window.location.origin
    });

    localStorage.removeItem("DebugAuthResult");
    history.replace("/");
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    } else if (expiresAt) {
      this.renewSession();
    }
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt));
  }
}
