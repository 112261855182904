import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { CssBaseline, AppBar, Toolbar, Typography } from "@material-ui/core";
import withAuth from "../hoc/withAuth";
import LogoutButton from "./LogoutButton";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    logoutWrap: {
      position: "absolute",
      right: 0
    },
    toolbar: theme.mixins.toolbar,
    heroUnit: {
      backgroundColor: theme.palette.background.paper
    },
    heroContent: {
      maxWidth: 600,
      margin: "0 auto",
      padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`
    },
    heroButtons: {
      marginTop: theme.spacing.unit * 4
    },
    main: {
      padding: "24px"
    }
  });

interface ErrorBoundaryProps extends WithStyles<typeof styles> {}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: any;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { auth, classes } = this.props as any;
    if (this.state.errorInfo) {
      // Error path
      return (
        <React.Fragment>
          <CssBaseline />
          <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {auth && auth.isAuthenticated() ? (
                <div className={classes.logoutWrap}>
                  <LogoutButton />
                </div>
              ) : null}
            </Toolbar>
          </AppBar>
          <main className={classes.main}>
            {/* Hero unit */}
            <div className={classes.heroUnit}>
              <div className={classes.heroContent}>
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  ArangoDB Data Sizer
                </Typography>
                <div>
                  <h2>
                    Something went wrong. Please contact support@arangodb.com.
                  </h2>
                  <h3>{this.state.error && this.state.error.toString()}</h3>
                  <details style={{ whiteSpace: "pre-wrap" }}>
                    <br />
                    {this.state.errorInfo.componentStack}
                  </details>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withAuth(withStyles(styles)(ErrorBoundary));
