import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#577138"
    },
    secondary: {
      main: "#C3CD97"
    }
  },
  typography: {
    subtitle1: {
      fontSize: 10
    }
  }
});
