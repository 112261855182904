import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Typography from "@material-ui/core/Typography";

import { Project } from "../store/projects/types";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

import { deleteProject } from "../store/projects/actions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../store/state";
import { Action } from "redux";
import { connect } from "react-redux";
import ProjectRow from "./ProjectRow";
import withAuth from "../hoc/withAuth";

interface ProjectListProps extends RouteComponentProps {
  auth?: any;
  projects: Project[];
  deleteProject: (accessToken: string, projectId: string) => Promise<void>;
  onEdit: (projectId: string) => void;
}

const ProjectList = (props: ProjectListProps) => {
  const { auth, history, onEdit, deleteProject, projects } = props;
  const [confirmDeleteId, setConfirmDeleteId] = useState();

  let confirmDeleteDialog;
  if (confirmDeleteId !== undefined) {
    const doDelete = async () => {
      const accessToken = await auth.getAccessToken();

      await deleteProject(accessToken, confirmDeleteId);
      setConfirmDeleteId(undefined);
    };
    confirmDeleteDialog = (
      <ConfirmDeleteDialog
        onCancel={() => setConfirmDeleteId(undefined)}
        onConfirm={doDelete}
      />
    );
  }

  const onDelete = (projectId: string) => {
    setConfirmDeleteId(projectId);
  };

  const gotoProject = (projectId: string) => {
    history.push("/projects/" + projectId);
  };

  const projectRows = projects.map((project, index) => {
    return (
      <ProjectRow
        key={index}
        project={project}
        onDelete={onDelete}
        onEdit={onEdit}
        gotoProject={gotoProject}
      />
    );
  });

  return (
    <React.Fragment>
      {confirmDeleteDialog}
      <Typography variant="h4" gutterBottom component="h2">
        Projects
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Collections</TableCell>
              <TableCell align="right">Total Data Size</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>{projectRows}</TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, any, Action>
): Pick<ProjectListProps, "deleteProject"> => {
  return {
    deleteProject: (accessToken: string, projectId: string) =>
      dispatch(deleteProject(accessToken, projectId))
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(withRouter(withAuth(ProjectList)));
