import { applyMiddleware, compose, createStore, combineReducers } from "redux";

import thunk from "redux-thunk";
import logger from "redux-logger";

import projects from "./projects/reducer";

const createRootReducer = () =>
  combineReducers({
    projects: projects
  });

export default function configureStore() {
  const store = createStore(
    createRootReducer(),
    compose(applyMiddleware(logger, thunk))
  );

  return store;
}
