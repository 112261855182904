import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CollectionsIcon from "@material-ui/icons/Collections";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";
import { RootState } from "../store/state";
import { Project } from "../store/projects/types";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import withProjects from "../hoc/withProjects";
import withAuth from "../hoc/withAuth";

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    backButton: {
      marginLeft: 12,
      marginRight: 36
    },
    title: {
      flexGrow: 1
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      height: "100vh",
      overflow: "auto"
    },
    chartContainer: {
      marginLeft: -22
    },
    tableContainer: {
      height: 320
    },
    h5: {
      marginBottom: theme.spacing.unit * 2
    },
    link: {
      textDecoration: "none"
    }
  });

interface WizardProps extends RouteComponentProps, WithStyles<typeof styles> {
  id: string;
  title: string;
  auth: any;
}

const Wizard: React.FunctionComponent<WizardProps> = props => {
  const { id, title, history, classes, children, auth } = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {auth.isAdmin() ? (
            <IconButton
              color="inherit"
              aria-label="back"
              onClick={() => history.push("/")}
              className={classes.backButton}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            ""
          )}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <Link to={"/projects/" + id} className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="Summary" />
            </ListItem>
          </Link>
          <Link
            to={"/projects/" + id + "/collections"}
            className={classes.link}
          >
            <ListItem button>
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary="Collections" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const project = state.projects.projects.find(project => {
    return project.id === props.id;
  }) as Project;
  return {
    title: project.name
  };
};

export default withProjects(
  connect(mapStateToProps)(withAuth(withStyles(styles)(Wizard)))
);
