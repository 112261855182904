import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { fetchProjects } from "../store/projects/actions";
import withAuth from "./withAuth";
import { RootState } from "../store/state";

interface WithProjectProps {
  auth?: any;
  projectsFetched: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    projectsFetched: state.projects.projectsFetched
  };
};

export default function withProjects<P extends object>(
  Component: React.ComponentType<P>
) {
  return connect(mapStateToProps)(
    withAuth((props: any) => {
      const { auth, projectsFetched } = props;
      const dispatch = useDispatch();

      useEffect(() => {
        auth
          .getAccessToken()
          .then((accessToken: string) => dispatch(fetchProjects(accessToken)));
      }, [auth, dispatch]);

      if (!projectsFetched) {
        return null;
      }

      return <Component {...props} />;
    })
  );
}
