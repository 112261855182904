import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Project, MachineType } from "../store/projects/types";

interface CreateProjectDialogProps {
  open: boolean;
  project: Project | undefined;
  validate?: (title: string) => boolean;
  onClose: () => void;
  onSave: (project: Project) => void;
}

const ProjectDialog = (props: CreateProjectDialogProps) => {
  const [projectName, setProjectName] = useState(
    props.project ? props.project.name : ""
  );

  let title;
  let submitTitle;
  if (props.project) {
    title = "Edit project";
    submitTitle = "Edit";
  } else {
    title = "Create project";
    submitTitle = "Create";
  }

  const saveProject = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let project: Project;

    if (props.project) {
      project = {
        ...props.project,
        name: projectName
      };
    } else {
      project = {
        name: projectName,
        collections: [],
        datacenters: 1,
        replicationFactor: 3,
        machineType: MachineType.Generic,
        genericMachineMemory: 32, // GiB
        awsInstanceType: "i3.2xlarge",
        systemMemory: 3,
        rocksDBWriteCache: 10,
        queryResultCache: 5,
        memoryForReplicas: 30,
        sizeHotData: 20,
        growth: 1,
        growthUnit: "year",
        numberOfGrowthUnits: 1,
        lsmTreeOverhead: 15,
        compressionRate: 50,
        peakSpaceFactorUncompressed: 150,
        peakSpaceFactorCompressed: 100,
        updatedData: 5
      };
    }
    props.onSave(project);
  };
  const valid = projectName.match(/^[a-zA-Z-_0-9 ]+$/);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={saveProject}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter a project name</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Project name"
            type="text"
            fullWidth
            onChange={event => setProjectName(event.target.value)}
            value={projectName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!valid}>
            {submitTitle}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProjectDialog;
