import React from "react";
import {
  FieldType,
  Field,
  IndexType,
  CollectionType
} from "../store/projects/types";

import DeleteIcon from "@material-ui/icons/Delete";
import SubdirectoryIcon from "@material-ui/icons/SubdirectoryArrowRight";
import CopyIcon from "@material-ui/icons/FileCopy";

import {
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox
} from "@material-ui/core";
import { fieldNameValid } from "../functions/field";

const fieldLength = (
  path: number[],
  field: Field,
  classes: any,
  setField: (path: number[], field: Field) => void
) => {
  if (field.type === FieldType.STRING || field.type === FieldType.ARRAY) {
    if (field.name === "_rev" || field.name === "_id") {
      return field.length;
    }
    return (
      <TextField
        type="number"
        id="name"
        margin="normal"
        value={field.length + ""}
        onChange={event => {
          setField(path, {
            ...field,
            length: parseInt(event.target.value, 10)
          });
          event.stopPropagation();
        }}
      />
    );
  } else {
    let matches = field.type.match(/^Int(\d+)$/);
    if (matches && matches.length > 1) {
      return (
        <FormControl className={classes.formControl}>
          <Select
            value={matches[1]!}
            onChange={event => {
              let fieldLength = event.target.value;
              setField(path, {
                ...field,
                type: ("Int" + fieldLength) as FieldType
              });
            }}
            inputProps={{
              name: "type_" + path.join("_"),
              id: "type_" + path.join("_")
            }}
          >
            {integerLengths.map((length: string) => (
              <MenuItem key={length} value={length}>
                {length}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return "-";
  }
};

const fieldType = (
  path: number[],
  field: Field,
  classes: any,
  setField: (path: number[], field: Field) => void
) => {
  if (
    isInternalField(field) ||
    field.type === FieldType.OBJECT ||
    field.type === FieldType.ARRAY
  ) {
    return field.type;
  } else {
    const fieldType = field.type.match(/^Int/) ? "Integer" : field.type;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={"type_" + path.join("_")}>Type</InputLabel>
        <Select
          value={fieldType}
          onChange={event => {
            if (event.target.value === "Integer" && fieldType === "Integer") {
              return;
            }

            let newFieldType = event.target.value;
            if (event.target.value === "Integer") {
              newFieldType = "Int32";
            }
            setField(path, {
              ...field,
              type: newFieldType as FieldType
            });
          }}
          inputProps={{
            name: "type_" + path.join("_"),
            id: "type_" + path.join("_")
          }}
        >
          {fieldTypes.map((type: string) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
};

const fieldTraversal = (
  path: number[],
  field: Field,
  classes: any,
  setField: (path: number[], field: Field) => void
) => {
  const disabled =
    isInternalField(field) ||
    // @ts-ignore
    field.type === FieldType.OBJECT ||
    // @ts-ignore
    field.type === FieldType.ARRAY;
  return (
    <FormControl className={classes.formControl}>
      <Checkbox
        disabled={disabled}
        checked={field.usedInTraversal}
        onChange={event =>
          setField(path, {
            ...field,
            usedInTraversal: event.target.checked
          })
        }
        inputProps={{
          "aria-label": "primary checkbox"
        }}
      />
    </FormControl>
  );
};

const fieldIndex = (
  path: number[],
  field: Field,
  classes: object,
  setField: (path: number[], field: Field) => void
) => {
  if (
    isInternalField(field) ||
    field.type === FieldType.OBJECT ||
    field.type === FieldType.ARRAY
  ) {
    return "-";
  }
  let value = "0";
  if (field.index !== undefined) {
    value = field.index;
  }
  return (
    <Select
      value={value}
      onChange={event => {
        if (event.target.value === "0") {
          const { index, ...oldField } = field;
          setField(path, oldField);
        } else {
          setField(path, {
            ...field,
            index: event.target.value as IndexType
          });
        }
      }}
      inputProps={{
        name: "index_" + path.join("_"),
        id: "index_" + path.join("_")
      }}
    >
      <MenuItem value={0}>None</MenuItem>
      <MenuItem value={IndexType.UNIQUE}>Unique</MenuItem>
      <MenuItem value={IndexType.NON_UNIQUE}>Non-Unique</MenuItem>
    </Select>
  );
};

const isInternalField = (field: Field) => {
  return (
    field.name === "_key" ||
    field.name === "_id" ||
    field.name === "_rev" ||
    field.name === "_from" ||
    field.name === "_to"
  );
};

const [fieldTypes, integerLengths] = Object.values(FieldType).reduce(
  ([fieldTypes, integerLengths], type) => {
    let match = type.match(/^Int(\d+)/);
    if (match) {
      if (
        fieldTypes.find((fieldType: string) => fieldType === "Integer") ===
        undefined
      ) {
        fieldTypes.push("Integer");
      }
      integerLengths.push(match[1]);
    } else if (type !== FieldType.OBJECT && type !== FieldType.ARRAY) {
      fieldTypes.push(type);
    }
    return [fieldTypes, integerLengths];
  },
  [[], []]
);

const renderRawField = function(
  parentType: FieldType | null,
  collectionType: CollectionType,
  field: Field,
  path: number[],
  classes: object,
  setField: (path: number[], field: Field) => void,
  copyField: (field: Field) => void,
  removeField: (path: number[]) => void
) {
  let indents = Array.from({ length: path.length - 1 }).map((k, index) => (
    <SubdirectoryIcon key={index} />
  ));
  const index = path[path.length - 1];
  return (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        {indents}
        {isInternalField(field) ? (
          field.name
        ) : (
          <TextField
            error={!fieldNameValid(field.name)}
            id={"name_" + index}
            margin="normal"
            value={field.name}
            onChange={event => {
              setField(path, {
                ...field,
                name: event.target.value.replace(/^_/, "")
              });
            }}
          />
        )}
      </TableCell>
      <TableCell>{fieldType(path, field, classes, setField)}</TableCell>
      <TableCell>{fieldLength(path, field, classes, setField)}</TableCell>
      <TableCell>{fieldIndex(path, field, classes, setField)}</TableCell>
      {collectionType === CollectionType.EDGE ? (
        <TableCell>{fieldTraversal(path, field, classes, setField)}</TableCell>
      ) : null}
      <TableCell>
        {isInternalField(field) ? null : (
          <IconButton
            aria-label="Copy"
            color="primary"
            onClick={() => copyField(field)}
          >
            <CopyIcon />
          </IconButton>
        )}
        {/* ok this is ugly...prevent first field delete so we don't end up with empty arrays */}
        {isInternalField(field) || parentType === FieldType.ARRAY ? null : (
          <IconButton
            aria-label="Delete"
            color="primary"
            onClick={() => removeField(path)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const renderTableField = function(
  parentType: FieldType | null,
  collectionType: CollectionType,
  field: Field,
  path: number[],
  classes: object,
  setField: (path: number[], field: Field) => void,
  copyField: (field: Field) => void,
  removeField: (path: number[]) => void
) {
  const index = path[path.length - 1];
  if (field.type === FieldType.ARRAY) {
    return (
      <React.Fragment key={index}>
        {renderRawField(
          parentType,
          collectionType,
          field,
          path,
          classes,
          setField,
          copyField,
          removeField
        )}
        {renderTableField(
          field.type,
          collectionType,
          field.array!,
          path.concat([0]),
          classes,
          setField,
          copyField,
          removeField
        )}
      </React.Fragment>
    );
  } else if (field.type === FieldType.OBJECT) {
    return (
      <React.Fragment key={index}>
        {renderRawField(
          parentType,
          collectionType,
          field,
          path,
          classes,
          setField,
          copyField,
          removeField
        )}
        {field.object!.map((field, index) =>
          renderTableField(
            FieldType.OBJECT,
            collectionType,
            field,
            path.concat([index]),
            classes,
            setField,
            copyField,
            removeField
          )
        )}
      </React.Fragment>
    );
  } else {
    return renderRawField(
      parentType,
      collectionType,
      field,
      path,
      classes,
      setField,
      copyField,
      removeField
    );
  }
};

interface CollectionDialogFieldRowProps {
  collectionType: CollectionType;
  field: Field;
  path: number[];
  classes: Record<"formControl", string>;
  setField: (path: number[], field: Field) => void;
  copyField: (field: Field) => void;
  removeField: (path: number[]) => void;
}

const CollectionDialogFieldRow = (props: CollectionDialogFieldRowProps) => {
  const {
    collectionType,
    classes,
    field,
    path,
    setField,
    copyField,
    removeField
  } = props;
  return renderTableField(
    null,
    collectionType,
    field,
    path,
    classes,
    setField,
    copyField,
    removeField
  );
};

export default CollectionDialogFieldRow;
