export * from "./actiontypes";

export enum FieldType {
  STRING = "String",
  DATESTRING = "Datestring",
  DATE = "Date",
  NULL = "NULL",
  BOOL = "Bool",
  INT8 = "Int8",
  INT16 = "Int16",
  INT24 = "Int24",
  INT32 = "Int32",
  INT40 = "Int40",
  INT48 = "Int48",
  INT56 = "Int56",
  INT64 = "Int64",
  DOUBLE = "Double",
  OBJECT = "Object",
  ARRAY = "Array"
}

export enum IndexType {
  UNIQUE = "unique",
  NON_UNIQUE = "nonunique"
}

export enum CollectionType {
  DOCUMENT = "document",
  EDGE = "edge"
}

export interface Field {
  name: string;
  type: FieldType;
  length: number;
  usedInTraversal: boolean;
  index?: IndexType;
  object?: Field[];
  array?: Field;
}

export interface Collection {
  name: string;
  documents: number;
  vertices: number; // only for edge collection
  type: CollectionType;
  fields: Field[];
}

export enum MachineType {
  Generic = "generic",
  AWS = "aws"
}

export interface Project {
  id?: string;
  name: string;
  collections: Collection[];
  replicationFactor: number;
  datacenters: number;
  machineType: MachineType;
  genericMachineMemory: number;
  awsInstanceType: string;
  systemMemory: number;
  rocksDBWriteCache: number;
  queryResultCache: number;
  memoryForReplicas: number;
  sizeHotData: number;
  growth: number;
  numberOfGrowthUnits: number;
  growthUnit: "week" | "month" | "year";
  lsmTreeOverhead: number;
  compressionRate: number;
  peakSpaceFactorUncompressed: number;
  peakSpaceFactorCompressed: number;
  updatedData: number;
}
