import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Collection, CollectionType } from "../store/projects/types";
import Grid from "@material-ui/core/Grid";
import { fromTemplateDocument as collectionFromTemplateDocument } from "../functions/collection";

import CollectionFormProperties from "./CollectionFormProperties";
import { useDebounce } from "../hooks/debounce";

interface CollectionImportDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (collection: Collection) => void;
}

const CollectionImportDialog = (props: CollectionImportDialogProps) => {
  const { onClose, onSave } = props;

  const initialState = {
    numDocuments: 100000,
    numVertices: 100000,
    name: "",
    type: "document" as "document" | "edge",
    document: JSON.stringify({ my: "document" }, null, 2)
  };

  const [
    { name, type, numDocuments, numVertices, document },
    setState
  ] = useState(initialState);

  const createSetProperty = (property: string) => {
    return function<T>(value: T) {
      return setState(prevState => ({
        ...prevState,
        [property]: value
      }));
    };
  };

  const setDocument = function(document: string) {
    setState(prevState => ({
      ...prevState,
      document
    }));
  };

  let title = "Create collection from document template";
  let submitTitle = "Create";

  const clearState = () => {
    setState(initialState);
    setCollection(undefined);
  };

  const [error, setError] = useState();
  const [collection, setCollection] = useState();

  const debouncedDocument = useDebounce(document, 100);
  // Effect for API call
  useEffect(() => {
    try {
      const collectionType =
        type === "edge" ? CollectionType.EDGE : CollectionType.DOCUMENT;
      setCollection(
        collectionFromTemplateDocument(
          name,
          collectionType,
          numDocuments,
          numVertices,
          debouncedDocument
        )
      );
      setError(undefined);
    } catch (e) {
      setError(e.toString());
    }
  }, [name, type, numDocuments, debouncedDocument, numVertices]);

  const metaDataOk = name.length > 0 && numDocuments > 0;

  const editOk =
    debouncedDocument === document && !error && collection && metaDataOk;

  function save(event: React.FormEvent<HTMLFormElement> | React.MouseEvent) {
    event.preventDefault();
    onSave(collection);
    clearState();
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form onSubmit={save}>
        <DialogContent>
          <CollectionFormProperties
            name={name}
            type={type}
            numberOfDocuments={numDocuments}
            numberOfVertices={numVertices}
            setName={createSetProperty("name")}
            setType={createSetProperty("type")}
            setNumberOfDocuments={createSetProperty("numDocuments")}
            setNumberOfVertices={createSetProperty("numVertices")}
          />
          <Grid container spacing={24}>
            <TextField
              error={!!error}
              helperText={error}
              id="outlined-multiline-flexible"
              label="Example Document"
              multiline
              fullWidth
              value={document}
              onChange={e => setDocument(e.target.value)}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={save}
              disabled={!editOk}
              color="primary"
              type="submit"
            >
              {submitTitle}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CollectionImportDialog;
